import * as React from "react"
import logo from "../images/logo.png"
import background from "../images/daikin-heatpumps-mock.jpg"
import Helmet from "react-helmet"

const IndexPage = () => {
  return (
   <>
   <Helmet>
     <title>Save up to $450 on Daikin mini-split heat pumps | ATL-Refac</title>
   </Helmet>
    <div className="main">
      <div className="container">
        <div className="wrapper">
          <img src={logo} alt="" class="logo" />
          <div className="content">
            <h1>Save up to $450 on Daikin<br/>mini-split&nbsp;heat&nbsp;pumps</h1>
            
            <h2>Thank you for your interest. Someone will be in touch with you soon!</h2>
            
          </div>
          
          
          <div className="copyright">
            <span>COPYRIGHT © 2021 ATL-REFAC</span>
          </div>
        </div>
      </div>
      <div className="container"  style={{backgroundImage:'url('+background+')'}}></div>
    </div>
   </> 
  )
}

export default IndexPage
